import { render, staticRenderFns } from "./NuevaPostulacion.vue?vue&type=template&id=32255370&scoped=true"
import script from "./NuevaPostulacion.vue?vue&type=script&lang=js"
export * from "./NuevaPostulacion.vue?vue&type=script&lang=js"
import style0 from "./NuevaPostulacion.vue?vue&type=style&index=0&id=32255370&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32255370",
  null
  
)

export default component.exports