<script>
import { mapGetters } from 'vuex';

export default {
    name: 'datosPersonalesPostulacion',
    data() {
        return {
            departamentos: [],
            municipios: []
        }
    },
    computed: {
        ...mapGetters([
            'getPaises',
            'getDepartamentos',
            'getMunicipios',
        ]),
        listaTipoDocumento() {
            return this.$store.getters.getTiposDeDocumentos
        },
        listaCategoriaLicencia() {
            return this.$store.getters.getTiposDeLicenciaDeConduccion
        },
        persona() {
            return this.$store.getters.datosPersonales
        }
    },
    methods: {
        logPersona(){
            console.log(this.persona);
        },
        assertData() {
            this.$store.getters.fetchPost({ path: 'Postulacion/DatosPersonales', data: this.persona })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Error al guardar los datos personales')
                })
                .then(result => {
                    this.$store.commit('setStateByKey', { key: 'postulacionId', value: result.id })
                    console.log(result);
                    this.$emit('nextStep');
                })
                .catch(error => {
                    console.log(error);
                })
        },
        setDepartamentosInElement( paisId = null) {
            this.$store.getters.fetchGet({ path: 'DepartamentoPais/ListByPais/' + paisId })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error(response.status)
                })
                .then(data => {
                    this.departamentos = data
                    this.departamentos = [...this.departamentos]
                })
                .catch(error => console.error(error))
        },
        setMunicipiosInElement(departamentoId = null) {
            this.$store.getters.fetchGet({ path: 'Municipio/ListByDepartamento/' + departamentoId })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error(response.status)
                })
                .then(data => {
                    this.municipios = data
                    this.municipios = [...this.municipios]
                })
                .catch(error => console.error(error))
        }

    }
}
</script>

<template>
    <b-form @submit.prevent="assertData">
        <b-row class="pb-2">
            <b-col sm>
                <label for="informacionPersonalCorreo" class="form-label">Correo electrónico</label>
                <b-form-input v-model="persona.correo" type="email" id="informacionPersonalCorreo" required />
                </b-col>
        </b-row>

        <b-row class="pb-2">
            <b-col sm>
                <label for="informacionPersonalNombre" class="form-label">Nombres</label>
                <b-form-input v-model="persona.nombres" type="text" id="informacionPersonalNombre" required />
            </b-col>
            <b-col sm>
                <label for="informacionPersonalApellidos" class="form-label">Apellidos</label>
                <b-form-input v-model="persona.apellidos" type="text" id="informacionPersonalApellidos" required />
            </b-col>
        </b-row>
        <b-row class="pb-2">
            <b-col sm>
                <label for="informacionPersonalTipoDeDocumento" class="form-label">Tipo de documento</label>
                <b-form-select required v-model="persona.tipoDocumentoId" text-field="descripcion" value-field="id"
                    :options="listaTipoDocumento">
                    <template #first>
                        <b-form-select-option selected :value="null" disabled>
                            Seleccionar
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col sm>
                <label for="informacionPersonalNumeroDeDocumento" class="form-label">Número de documento</label>
                <b-form-input v-model="persona.numeroDocumento" type="text" id="informacionPersonalNumeroDeDocumento"
                    required />
            </b-col>
        </b-row>
        <b-row class="pb-2">
            <b-col sm>
                <label for="informacionPersonalTelefonoCelular" class="form-label">Teléfono Celular</label>
                <b-form-input v-model="persona.telefonoCelular" type="text" id="informacionPersonalTelefonoCelular"
                    required />
            </b-col>
            <b-col sm>
                <label for="informacionPersonalTelefonoFijo" class="form-label">Teléfono Fijo (Opcional)</label>
                <b-form-input v-model="persona.telefonoFijo" type="text" id="informacionPersonalTelefonoFijo" />
            </b-col>
        </b-row>
        <b-row class="pb-2">
            <b-col sm>
                <label for="informacionPersonalCategoria" class="form-label">Categoría Licencia (Opcional)</label>
                <b-form-select v-model="persona.licenseCategoryId" text-field="description" value-field="id"
                    :options="listaCategoriaLicencia">
                    <template #first>
                        <b-form-select-option selected :value="null" disabled>
                            Seleccionar
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>

            <b-col sm>
                <label for="informacionPersonalLicenciaDeConduccion" class="form-label">Número de licencia de
                    conducción (Opcional)</label>
                <b-form-input v-model="persona.licenseNumber" type="text" id="informacionPersonalLicenciaDeConduccion" />
            </b-col>
        </b-row>
        <b-row class="pb-2">
            <b-col sm>
                <label for="informacionPersonalDireccion" class="form-label">Dirección de domicilio</label>
                <b-form-input v-model="persona.direccion" type="text" id="informacionPersonalDireccion" required />
            </b-col>
            <b-col sm>
                <label for="informacionPersonalBarrio" class="form-label">Barrio</label>
                <b-form-input v-model="persona.neighborhood" type="text" id="informacionPersonalBarrio" required />
            </b-col>

        </b-row>
        <b-row class="pb-2">
            <b-col sm>
                <label for="informacionPersonalLocalidad" class="form-label">Localidad</label>
                <b-form-input v-model="persona.locality" type="text" id="informacionPersonalLocalidad" required />
            </b-col>
            <b-col sm>
                <label for="informacionPersonalEstrato" class="form-label">Estrato</label>
                <b-form-input v-model="persona.stratum" type="text" id="informacionPersonalEstrato" required />
            </b-col>
        </b-row>
        <b-row class="pb-2">
            <b-col sm>
                <label for="informacionPersonalContactoDeEmergencia" class="form-label">Contacto de emergencia</label>
                <b-form-input v-model="persona.emergencyContact" type="text" id="informacionPersonalContactoDeEmergencia"
                    required />
            </b-col>
            <b-col sm>
                <label for="informacionPersonalTelefonoDeEmergencia" class="form-label">Teléfono de emergencia</label>
                <b-form-input v-model="persona.emergencyContactNumber" type="text"
                    id="informacionPersonalTelefonoDeEmergencia" required />
            </b-col>
        </b-row>

        <b-row class="pb-2">
                <b-col sm>
                    <label for="educacionPais" class="form-label">País</label>
                    <b-form-select required v-model="persona.paisId" text-field="descripcion" value-field="id"
                        :options="getPaises" 
                        @change.native="setDepartamentosInElement( persona.paisId)">
                        <template #first>
                            <b-form-select-option selected :value="null" disabled>
                                Seleccionar
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col sm>
                    <label for="educacionDepartamento" class="form-label">Departamento</label>
                    <b-form-select required v-model="persona.departamentoId" text-field="descripcion" value-field="id"
                        :options="departamentos"
                        @change.native="setMunicipiosInElement( persona.departamentoId)">
                        <template #first>
                            <b-form-select-option selected :value="null" disabled>
                                Seleccionar
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>

            <b-row class="pb-2">
                <b-col sm>
                    <label for="educacionMunicipio" class="form-label">Municipio</label>
                    <b-form-select required v-model="persona.municipioId" text-field="descripcion" value-field="id"
                        :options="municipios">
                        <template #first>
                            <b-form-select-option selected :value="null" disabled>
                                Seleccionar
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col sm>
                    <label for="educacionFecha" class="form-label">Fecha de nacimiento</label>
                    <b-form-input v-model="persona.dateBorn" type="date" id="educacionFecha" required/>
                </b-col>
            </b-row>
        <div class="d-flex flex-row-reverse" style="width: 100%">
            <button class="btn px-4 py-2 btn-outline-success">
                <span> Guardar y continuar </span>
            </button>
        </div>

</b-form></template>