condicional de si es de otra area de la empresa o es nuevo.
Entonces si es nuevo, se muestra el formulario de postulacion.
De lo contrario se carga la hoja de vida existente.
<script>
import Swal from "sweetalert2";
import DatosPersonalesSeccion from "./componentesNuevaPostulacion/datosPersonales.vue";
import EducacionFormalSeccion from "./componentesNuevaPostulacion/educacionFormal.vue";
import EducacionNoFormalSeccion from "./componentesNuevaPostulacion/educacionNoFormal.vue";
import ExperienciaProfesionalSeccion from "./componentesNuevaPostulacion/experienciaProfesional.vue";
import InformacionFamiliarSeccion from "./componentesNuevaPostulacion/informacionFamiliar.vue";
import ReferenciasPersonalesSeccion from "./componentesNuevaPostulacion/referenciasPersonales.vue";


export default {
    name: "NuevaPostulacion",
    components: {
        DatosPersonalesSeccion,
        EducacionFormalSeccion,
        EducacionNoFormalSeccion,
        ExperienciaProfesionalSeccion,
        InformacionFamiliarSeccion,
        ReferenciasPersonalesSeccion,
    },
    data() {
        return {
            seccionesDelFormulario: [
                { name: "datosPersonales", text: "Datos Personales" },
                { name: "educacionFormal", text: "Educación Formal" },
                { name: "educacionNoFormal", text: "Educación No Formal" },
                { name: "experienciaProfesional", text: "Experiencia Profesional" },
                { name: "informacionFamiliar", text: "Información Familiar" },
                { name: "referenciasPersonales", text: "Referencias Personales" },
            ],
            seccionSeleccionada: 0,
            last: 0,
            talentoInternoId: null,
        };
    },
    mounted() {
        this.$store.dispatch('setInitData');
        this.$store.commit('setVacanteId', this.$route.params.vacanteId)
    },
    methods: {
        nextStep() {
            this.seccionSeleccionada++
            this.last = this.last < this.seccionSeleccionada ? this.seccionSeleccionada : this.last
        },
        goTo(idx) {
            this.seccionSeleccionada = idx
            // this.seccionSeleccionada = idx <= this.last ? idx : this.seccionSeleccionada
        },
        endOfPostulacion() {

            // console.log({
            //     datosPersonales: this.$store.getters.datosPersonales,
            //     resumenExperiencia: this.$store.getters.resumenExperiencia,
            //     resumenFormacion: this.$store.getters.resumenFormacion,
            //     resumenFormacionNoFormal: this.$store.getters.resumenFormacionNoFormal,
            //     resumenInformacionFamiliar: this.$store.getters.resumenInformacionFamiliar,
            //     resumenReferencias: this.$store.getters.resumenReferencias,
            // });


            Swal.fire({
                title: 'Postulación exitosa',
                text: 'Tu postulación ha sido enviada con éxito, en breve nos pondremos en contacto contigo.',
                icon: 'success',
                confirmButtonText: 'Ok'
            })
                .then(() => {
                    this.$router.push({ name: 'ListaDeVacantes' })
                })

        },
        showTalentoInterno() {
            this.$bvModal.show("modal-competencia")
        },
        postulacionTalentoInterno() {
            // this.$store.getters.fetchGet({ path: `Persona/GetPersona/${this.talentoInternoId}`})
            this.$store.getters.fetchGet({ path: `Persona/GetPersonaCC/${this.talentoInternoId}` })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error("No se ha Encontrado el usuario")
                })
                .then(result => {
                    console.log(result);
                    let persona = {
                        nombres: result.nombres,
                        apellidos: result.apellidos,
                        tipoDocumentoId: result.tipoDocumentoId,
                        numeroDocumento: result.numeroDocumento,
                        telefonoCelular: result.telefonoMovil,
                        telefonoFijo: result.telefonoFijo,
                        direccion: "Talento interno",
                        neighborhood: "Talento interno",
                        locality: "Talento interno",
                        stratum: "Talento interno",
                        emergencyContact: "Talento interno",
                        emergencyContactNumber: "Talento interno",
                        vacanteId: this.$route.params.vacanteId,
                        personaId: result.id,
                    }

                    this.$store.commit('setStateByKey', { key: 'persona', value: persona })

                    this.saveTalentoInterno()
                })
                .catch(error => {
                    Swal.fire({
                        title: 'Error',
                        text: error,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                })
        },
        saveTalentoInterno() {
            this.$store.getters.fetchPost({ path: 'Postulacion/DatosPersonales', data: this.$store.getters.datosPersonales })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                    else if (response.status === 400) {
                        throw new Error("La persona ya ha presentado su candidatura con anterioridad.")
                    }
                    throw new Error('Error al guardar los datos personales')
                })
                .then(() => {
                    this.dispatchSuccessPostulacion()
                })
                .catch(error => {
                    Swal.fire({
                        title: 'Error',
                        text: error,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                })
        },
        dispatchSuccessPostulacion() {

            Swal.fire({
                title: 'Postulación exitosa',
                text: 'Tu postulación ha sido enviada con éxito, en breve nos pondremos en contacto contigo.',
                icon: 'success',
                confirmButtonText: 'Ok'
            })
                .then(() => {
                    this.$router.go(-1);
                })

        }
    }
};
</script>

<template>
    <b-container fluid>
        <b-modal id="modal-competencia" class="modal-sq" size="lg" centered hide-footer>
            <template #modal-header="{ close }">
                <div class="d-flex justify-content-between" style="width: 100%;">
                    <p class="mb-0 h4"><strong>Postulacion talento interno</strong></p>
                    <b-button @click=" close()" class="btn btn-pm" variant="danger">
                        <i class="fas fa-times"></i>
                    </b-button>
                </div>
            </template>
            <template #default>
                <p>Si deseas postularte como talento interno, por favor ingresa tu código de identificación.</p>
                <b-form-input type="text" placeholder="ID" v-model="talentoInternoId" />
                <p class="my-2 text-justify">La información de tu hoja de vida existente se cargará automáticamente al
                    completar el
                    proceso de postulación como talento interno.</p>
                <div class="d-flex flex-row-reverse" style="width: 100%; gap: 5px">
                    <button class="btn px-4 py-2 btn-primary" @click="postulacionTalentoInterno">
                        <span> Postularse </span>
                    </button>
                </div>
            </template>
        </b-modal>
        <b-row>
            <b-col sm="12">
                <card>
                    <template v-slot:headerTitle>
                        <div class="d-flex">
                            <div class="mr-2">
                                <span class="pointer">
                                    <i class="fas fa-arrow-left fa-lg px-1" @click="$router.go(-1)"></i>
                                </span>
                            </div>
                            <h4 class="card-title"><b>Postulación</b></h4>
                        </div>
                    </template>
                    <div class="d-flex flex-row-reverse py-2 px-5" style="width: 100%; gap: 5px">
                        <button class="btn px-4 py-2 btn-primary" @click="showTalentoInterno">
                            <span> ¿Eres de talento interno? </span>
                        </button>
                    </div>
                    <div class="px-5">
                        <div class="buttons btn-group">
                            <button v-for="(seccion, idx) in seccionesDelFormulario" :key="seccion.name + '-btn'"
                                class="btn btn-primary btn-lg" :class="{ 'active': idx == seccionSeleccionada }"
                                @click="goTo(idx)">
                                <b>{{ seccion.text }}</b>
                            </button>
                        </div>
                    </div>

                    <div class="px-5 my-1">
                        <h3>{{ seccionesDelFormulario[seccionSeleccionada].text }}</h3>
                    </div>


                    <!-- todo: Validar que exista el id de la vacante o de lo contrario llevar a 404 -->
                    <!-- todo: persistencia de la informacion al actualizar hasta completar el ultimo item -->

                    <section class="px-5 py-3">
                        <DatosPersonalesSeccion v-if="seccionSeleccionada == 0" @nextStep="nextStep" />
                        <EducacionFormalSeccion v-else-if="seccionSeleccionada == 1" @nextStep="nextStep" />
                        <EducacionNoFormalSeccion v-else-if="seccionSeleccionada == 2" @nextStep="nextStep" />
                        <ExperienciaProfesionalSeccion v-else-if="seccionSeleccionada == 3" @nextStep="nextStep" />
                        <InformacionFamiliarSeccion v-else-if="seccionSeleccionada == 4" @nextStep="nextStep" />
                        <ReferenciasPersonalesSeccion v-else-if="seccionSeleccionada == 5"
                            @endOfPostulacion="endOfPostulacion" />
                    </section>
                </card>
            </b-col>
        </b-row>
    </b-container>
</template>

<style scoped>
.buttons {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0;
    border-radius: 11px;
}

.buttons>* {
    border-radius: 0;
}
</style>

  
 